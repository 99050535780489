import React from 'react';
import { Modal } from '../../ui/Modal';
import Button from '../../ui/Button';
import { ButtonTypes } from '../../ui/Button/button-types';

interface ForceNewPasswordModalProps {
  readonly isOpen: boolean;
  readonly navigate: (location: string) => void;
  readonly title: string;
  readonly message: string;
}

function goToRecoveryPassword(navigate: (location: string) => void) {
  navigate('/forgot-password');
}
function ForceNewPasswordModal({
  isOpen,
  navigate,
  title,
  message
}: ForceNewPasswordModalProps): JSX.Element {
  return (
    <Modal.Root animation={false} show={isOpen}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id='btn-force-new-password-challenge'
          onClick={() => goToRecoveryPassword(navigate)}
          buttonType={ButtonTypes.Primary}
        >
          Alterar senha
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

ForceNewPasswordModal.displayName = 'ForceNewPasswordModal';

export default ForceNewPasswordModal;
