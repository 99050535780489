import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer, Loader } from '../helpers';

import './intro.css';

interface IntroProps {
  complete?: boolean;
  pending?: boolean;
  username: string;
}

const Intro = ({ pending, complete, username }: IntroProps): JSX.Element => {
  const { t } = useTranslation();

  const firstName = username.split(' ')[0];

  if (pending && !complete) {
    return (
      <>
        <Spacer size='small' />
        <Loader />
        <Spacer size='small' />
      </>
    );
  } else {
    return (
      <div id='intro'>
        <h1 id='heading'>{t('learn.heading', { name: firstName })}</h1>
        <div className='text-center'>
          <div className='blockquote'>
            <span>
              <p className='quote-author'>{t('learn.introduction')}</p>
            </span>
          </div>
        </div>
      </div>
    );
  }
};

Intro.displayName = 'Intro';

export default Intro;
