import React from 'react';
import { ChallengeNode } from '../../../redux/prop-types';
import { SuperBlocks } from '../../../../../config/certification-settings';
import {
  calculateAmountOfCompletedPhases,
  verifyPhasesCount,
  verifyTrailAccess
} from '../../../utils/all-challenge-nodes/calculate-challenges';
import { RegistrationResponseProps } from '../../../utils/ajax';
import MapItem from './map-item';

type LearnMapProps = {
  readonly nodes: ChallengeNode[];
  readonly phases: ChallengeNode[];
  readonly completedChallengeIds: string[];
  readonly currentSuperBlock: SuperBlocks | null;
  readonly access: string;
  readonly cpf: string;
  readonly courseCode: string;
  readonly userRegistrationStatus: RegistrationResponseProps;
  readonly checkUserRegistration: () => void;
  readonly registrationQuiz: number;
  readonly isFPFCompleted: boolean;
};

export default function LearnMap({
  nodes,
  currentSuperBlock,
  access,
  cpf,
  courseCode,
  completedChallengeIds,
  phases,
  userRegistrationStatus,
  checkUserRegistration,
  registrationQuiz,
  isFPFCompleted
}: LearnMapProps): JSX.Element {
  const auditedSuperBlocks = nodes.filter(
    ({ challenge }) => challenge.superBlock !== currentSuperBlock
  );

  return (
    <ul data-test-label='learn-curriculum-map'>
      {auditedSuperBlocks.map(({ challenge }, index) => {
        const accessAllowed = verifyTrailAccess(
          access,
          challenge.superOrder,
          index,
          completedChallengeIds,
          phases,
          userRegistrationStatus,
          registrationQuiz,
          isFPFCompleted
        );

        const countPhases = verifyPhasesCount(challenge.superBlock, phases);
        const countPhasesCompleted = calculateAmountOfCompletedPhases(
          challenge.superBlock,
          phases,
          completedChallengeIds
        );

        return (
          <li
            key={`MapLis-${challenge.dashedName}`}
            id={`knowledge-fild-${index}`}
          >
            <MapItem
              key={challenge.dashedName}
              accessAllowed={accessAllowed}
              userAccess={access}
              userCpf={cpf}
              courseCode={courseCode}
              countPhases={countPhases}
              countPhasesCompleted={countPhasesCompleted}
              superBlock={challenge.superBlock}
              completedFirstChallenge={completedChallengeIds.length > 0}
              checkUserRegistration={checkUserRegistration}
              completedChallengeIds={completedChallengeIds}
            />
          </li>
        );
      })}
    </ul>
  );
}
