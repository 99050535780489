import { Button } from '@devstart/react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { executeGA, hardGoTo } from '../../../redux/actions';
import { closeModal } from '../../../templates/Challenges/redux/actions';
import { isCongratsCompleteAccessOpenSelector } from '../../../templates/Challenges/redux/selectors';
import { Modal } from '../../ui/Modal';

// Styles
import './modalCongratsCompleteAccess.css';

// Types
interface ModalCongratsCompleteAccessProps {
  readonly close: () => void;
  readonly executeGA: (data: { type: string; data: string }) => void;
  readonly isOpen: boolean;
  readonly navigate: (location: string) => void;
}

const mapStateToProps = createSelector(
  isCongratsCompleteAccessOpenSelector,
  (isOpen: boolean) => ({
    isOpen
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal('limitedAccess'),
      executeGA,
      navigate: hardGoTo
    },
    dispatch
  );

function returnToLearn(
  close: () => void,
  navigate: (location: string) => void
) {
  close();
  navigate('/learn');
}

function ModalCongratsCompleteAccess({
  isOpen,
  close,
  navigate,
  executeGA
}: ModalCongratsCompleteAccessProps): JSX.Element {
  const { t } = useTranslation();

  if (isOpen) {
    executeGA({ type: 'modal', data: '/ModalCongratsCompleteAccess' });
  }

  return (
    <Modal.Root animation show={isOpen} onHide={close}>
      <Modal.Header closeButton>{t('learn.payment-modal-title')}</Modal.Header>
      <Modal.Body>
        <div>
          <p>{t('learn.payment-modal-message')}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id='btn-payment-user-challenge'
          block={true}
          onClick={() => returnToLearn(close, navigate)}
        >
          <p id='title-payment-user'>{t('learn.payment-modal-close')}</p>
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

ModalCongratsCompleteAccess.displayName = 'ModalCongratsCompleteAccess';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCongratsCompleteAccess);
