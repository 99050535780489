import { graphql, withPrefix } from 'gatsby';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators, Dispatch } from 'redux';
import {
  CompletedChallenge,
  CourseCodeDataProps,
  User
} from '../redux/prop-types';
import { Loader } from '../components/helpers';
import Intro from '../components/Intro';
import MapTrail from '../components/MapTrail';
import LearnLayout from '../components/layouts/learn';
import ForceNewPasswordModal from '../components/modals/ForceNewPasswordModal';
import ModalCongratsCompleteAccess from '../components/modals/ModalCongratsCompleteAccess';
import {
  isSignedInSelector,
  userSelector,
  userFetchStateSelector,
  completedChallengesSelector,
  userRegistrationStatusSelector,
  courseCodeInfoSelector
} from '../redux/selectors';
import {
  executeGA,
  hardGoTo as navigate,
  checkUserRegistration
} from '../redux/actions';
import ContinueProgress from '../templates/Introduction/components/continue-progress';
import AccordionProgressBar from '../components/ui/Accordion/components/accordion-progress-bar';
import MainContentLayout from '../components/layouts/main-content-layout';
import { AccessLevel } from '../utils/enums/access-levels';
import { RegistrationResponseProps } from '../utils/ajax';
import {
  continueProgressAvailable,
  userCompletedFirstCheckpointChallenge
} from '../utils/general-functions';

import './learn.css';
import { openModal } from '../templates/Challenges/redux/actions';
import DiscordCommunityModal from '../components/modals/discord-community-modal';
import { SuperBlocks } from '../../../config/certification-settings';

interface FetchState {
  pending: boolean;
  complete: boolean;
  errored: boolean;
}

interface Challenge {
  id: string;
  superBlock: string;
}

// Interface para o nó de um desafio
interface ChallengeNode {
  node: {
    challenge: Challenge;
  };
}

// Interface para o conjunto de nós de desafios retornado pela query
interface AllChallengeNode {
  totalCount: number;
  edges: ChallengeNode[];
}

// Interface para os dados retornados pela query GraphQL
interface ChallengeDataCount {
  allChallengeNode: AllChallengeNode;
  countAllChallengeNode: AllChallengeNode;
}

interface LearnPageProps {
  readonly data: ChallengeDataCount;
  readonly isSignedIn: boolean;
  readonly fetchState: FetchState;
  readonly completedChallengeIds: string[];
  readonly user: User;
  readonly userRegistrationStatus: RegistrationResponseProps;
  readonly courseCodeInfo: CourseCodeDataProps;
  readonly navigate: (location: string) => void;
  readonly checkUserRegistration: (data: {
    cpf: string;
    courseCode: string;
  }) => void;
  readonly openModal: (args: string) => void;
}

const mapStateToProps = createSelector(
  userFetchStateSelector,
  isSignedInSelector,
  userSelector,
  completedChallengesSelector,
  userRegistrationStatusSelector,
  courseCodeInfoSelector,
  (
    fetchState: FetchState,
    isSignedIn: boolean,
    user: User,
    completedChallenges: CompletedChallenge[],
    userRegistrationStatus: RegistrationResponseProps,
    courseCodeInfo: CourseCodeDataProps
  ) => ({
    fetchState,
    isSignedIn,
    user,
    completedChallengeIds: completedChallenges.map(({ id }) => id),
    userRegistrationStatus,
    courseCodeInfo
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      executeGA,
      navigate,
      checkUserRegistration,
      openModal
    },
    dispatch
  );

function LearnPage({
  isSignedIn,
  fetchState: { pending, complete },
  user,
  data,
  completedChallengeIds,
  userRegistrationStatus,
  courseCodeInfo,
  navigate,
  checkUserRegistration,
  openModal
}: LearnPageProps) {
  const { t } = useTranslation();

  useEffect(() => {
    const courseCode = courseCodeInfo?.courseCodes?.[1]?.toString() ?? '0';

    if (
      user?.access === AccessLevel.LimitedNacional &&
      userCompletedFirstCheckpointChallenge(completedChallengeIds)
    ) {
      checkUserRegistration({ cpf: user.cpf, courseCode: courseCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCodeInfo]);

  useEffect(() => {
    const storedValue = localStorage.getItem('discordModalDoNotShowAgain');
    if (storedValue === 'false') {
      openModal('discordCommunity');
    } else if (storedValue === null) {
      openModal('discordCommunity');
    }
  }, [openModal]);

  if (complete && !isSignedIn) {
    navigate(withPrefix('/'));
    return <Loader fullScreen={true} />;
  }

  function getUniqStringArray(arr: string[]): string[] {
    const stringSet = new Set<string>();

    arr.forEach(str => {
      stringSet.add(str);
    });

    const uniqArray = Array.from(stringSet);

    return uniqArray;
  }

  const amountOfCompletedChallenges = getUniqStringArray(
    completedChallengeIds
  ).length;

  const amountOfChallenges = data.countAllChallengeNode.totalCount;
  const amountOfChallengesDefault = data.countAllChallengeNode.edges.filter(
    (item: ChallengeNode) =>
      item.node.challenge.superBlock !==
      SuperBlocks.FundamentoDaProgramacaoFrontEnd
  ).length;
  const amountOfChallengesTrailZero = data.countAllChallengeNode.edges.filter(
    (item: ChallengeNode) =>
      item.node.challenge.superBlock ===
      SuperBlocks.FundamentoDaProgramacaoFrontEnd
  ).length;

  return (
    <LearnLayout>
      <MainContentLayout
        title={t('metaTags:title')}
        showUnlockCard={user.access !== AccessLevel.COMPLETE}
      >
        <div className='home-container'>
          <Intro complete={complete} pending={pending} username={user.name} />

          <AccordionProgressBar
            completedCount={amountOfCompletedChallenges}
            numberChallenges={
              user.isFundamentosDaProgramacaoFrontEndCert === true
                ? amountOfChallenges
                : user.registrationQuiz == 1
                ? amountOfChallengesTrailZero
                : amountOfChallengesDefault
            }
            sentence='type1'
          />

          {continueProgressAvailable(user) && (
            <ContinueProgress
              completedChallengeIds={completedChallengeIds}
              access={user.access}
              userRegistrationStatus={userRegistrationStatus}
              userRegistrationQuiz={user.registrationQuiz}
              userAttemptFPF={user.attemptFPFCert}
            />
          )}

          <MapTrail access={user.access} />
        </div>
      </MainContentLayout>

      <ModalCongratsCompleteAccess />
      <DiscordCommunityModal />

      <ForceNewPasswordModal
        isOpen={complete && user.forceNewPassword}
        title='Atualização de senha necessária'
        message='O DEVstart atualizou o seu provedor de autenticação, por esse motivo, usuários que utilizavam senha devem realizar a atualização desta para continuar utilizando o sistema.'
        navigate={navigate}
      />
    </LearnLayout>
  );
}

LearnPage.displayName = 'LearnPage';

export default connect(mapStateToProps, mapDispatchToProps)(LearnPage);

export const query = graphql`
  query CompleteLimitedAccess {
    allChallengeNode(
      filter: { challenge: { superOrder: { eq: 0 }, order: { eq: 2 } } }
    ) {
      totalCount
      edges {
        node {
          challenge {
            id
            superBlock
          }
        }
      }
    }
    countAllChallengeNode: allChallengeNode {
      totalCount
      edges {
        node {
          challenge {
            id
            superBlock
          }
        }
      }
    }
  }
`;
